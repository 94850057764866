@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  html {
    font-family: "Raleway", sans-serif;
    color: #fff;
  }
}

@layer components {
  .btn-menu {
    @apply text-lg bg-none h-fit md:text-base;
  }

  .btn-menu.active {
    @apply text-purple-50 font-semibold;
  }
}
